import { useStaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Img from "gatsby-image";

const Header = ({ siteTitle }) => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "PVANewLogo1-transparent.PNG" }) {
                childImageSharp {
                    fixed(height: 180) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    return (
        <header>
            <div className="container">
                <div class="row">
                    <div class="col d-flex justify-content-center">
                        <Img fixed={data.file.childImageSharp.fixed} alt="PVA Logo" className="header-image" />
                    </div>
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
