import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Subscribe from "../components/subscribe";

const IndexPage = () => {
    return (
        <Layout>
            <SEO title="Prime Ventures Alliance" keywords={[`investment`, `opportunity`]} />

            <div>
                <div class="section2">
                    <div class="container">
                        <div>
                            <h1 className="header_h1">We are a Free Private Membership Club since 2010!</h1>
                        </div>
                    </div>
                </div>
                <div class="section2">
                    <div class="container">
                        <div className="header-seperator">
                            <h2>
                                PVA provides members with access to <span className="pva-blue">multiple unique and diverse</span>{" "}
                            </h2>
                            <h2>
                                3rd party <b>Profit Generation Systems</b> from around the world.
                            </h2>
                            <div className="header-seperator" />
                            <h3>Researched and hand-picked Ventures in…</h3>
                            <h3 className="pva-red">Cryptocurrency, Blockchain, FX, Affiliate Programs, Private Offers &amp; MORE!</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section2 container text-center">
                <h2 className="pva-blue">Get on our Email LIST for the TOP PICKS! Click HERE to Access:</h2>
            </div>
            <Subscribe />
        </Layout>
    );
};

export default IndexPage;
