import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

import "./webform.css";

const Subscribe = () => (
    <div className="row justify-content-center">
        <div className="col-lg-4 align-self-center">
            <form action="https://api.elasticemail.com/contact/add?version=2" data-id="zCnaLqvbhc" method="post" id="ewf_subscriptionForm_zCnaLqvbhc" className="Inline EWF__form">
                <div className="headers">
                    <p id="ewf_formdesc" style={{ display: "none" }} />
                    <p />
                </div>
                <fieldset style={{ border: "none" }}>
                    <div className="inputs">
                        <div>
                            <label htmlFor="email">Email</label>
                            <input className="form-control" maxLength={60} name="email" size={20} type="email" required placeholder="Please enter your email address" />
                        </div>
                        <div>
                            <label htmlFor="field_firstname">First Name</label>
                            <input className="form-control" maxLength={60} name="field_firstname" size={20} type="text" placeholder="Please enter your first name" />
                        </div>
                        <p>
                            <ReCAPTCHA sitekey="6Lca-iUUAAAAAAQ6T6vYEAp3YybZWpeKUXRJ5E8S" />
                        </p>
                    </div>
                    <div className="checkboxes" />
                    <div className="webform-lists" style={{ display: "none" }}>
                        <div style={{ display: "block" }} className="js-Prime Ventures Alliance">
                            <label className="container-box">
                                <input type="checkbox" name="publiclistid" id="qsnqWyGh" defaultValue="b04ffd44-b629-4a9e-95c7-ef420f2d4185" defaultChecked="checked" />
                            </label>
                            <label className="publiclistlabel" htmlFor="qsnqWyGh">
                                Prime Ventures Alliance
                            </label>
                        </div>
                    </div>
                    <p id="ewf_datadisclaimer">
                        We use Elastic Email as our marketing automation service. By submitting this form, you agree that the information you provide will be transferred to Elastic
                        Email for processing in accordance with their Terms of Use and Privacy Policy.
                    </p>
                </fieldset>
                <fieldset className="webform-options styles-updated" style={{ border: "none" }}>
                    <div id="trackconsentscript" />
                    <input type="submit" name="submit" value="Subscribe" id="eesubmit" className="EWF__subscribe" disabled="" />
                    <input type="hidden" name="publicaccountid" value="2dcb8992-823d-4b1c-bca1-7e62bfb1602c" />
                    <input type="hidden" name="publicformid" value="0d48e91c-7097-4602-ac14-f7669bbc370b" />
                    <input type="hidden" name="returnUrl" value="https://zavitz-8acff.firebaseapp.com/pending" />
                    <input type="hidden" name="activationReturnUrl" value="https://zavitz-8acff.firebaseapp.com/confirmed" />
                    <input type="hidden" name="alreadyactiveurl" value="https://zavitz-8acff.firebaseapp.com/confirmed" />
                    <input type="hidden" name="activationTemplate" value="" />
                    <input type="hidden" name="source" value="WebForm" />
                    <input type="hidden" id="ewf_captcha" name="captcha" value="true" />
                    <input type="hidden" name="notifyEmail" value="" />
                    <input id="ewf_verifyEmails" type="hidden" name="verifyemail" value="false" />
                </fieldset>
            </form>
        </div>
    </div>
);

export default Subscribe;
