/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";
import "./layout.css";

const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                        url
                    }
                }
            }
        `}
        render={data => (
            <>
                <Header siteTitle={data.site.siteMetadata.title} />
                <main>{children}</main>
                <footer>
                    <div className="container" style={{ color: "lightcoral" }}>
                        <div className="social" style={{ margin: "5%" }}>
                            <div className="d-flex flex-row justify-content-center">
                                <a href="https://www.facebook.com/groups/111014338929087/">
                                    <i className="fab fa-facebook-f m-2" />
                                </a>
                                <a href="http://twitter.com/PVAupdates">
                                    <i className="fab fa-twitter m-2" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center footer">
                        © {new Date().getFullYear()}, <a href={data.site.siteMetadata.url}>{data.site.siteMetadata.title}</a>
                    </div>
                </footer>
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
